



















import { Vue, Component } from "vue-property-decorator";
import Users from "@/store/users";
import FancyButton from "@/components/FancyButton.vue";

@Component({
  components: {
    FancyButton
  }
})
export default class extends Vue {
  headers = [
    { text: "Name", value: "name" },
    { text: "Email", value: "email" },
    { text: "Phone", value: "phoneNumber" },
    { text: "Title", value: "title" }
  ];

  get companies() {
    return Users.companies;
  }

  get loading() {
    return Users.loading;
  }

  get error() {
    return Users.error;
  }

  async mounted() {
    this.refresh();
  }

  async refresh() {
    await Users.fetchAll();
  }

  reload() {
    this.refresh();
    this.$forceUpdate();
  }

}
